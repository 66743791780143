import * as yup from 'yup'
import { BillingData, Country, FormattedAddress, validateInputField, validateRut } from '@ecommerce/shared'
import secrets from '../../../config/secrets'

export type UserData = {
  customer_name: string
  customer_last_name: string
  customer_rut: string
  customer_email: string
  customer_birth_day: string
  customer_birth_month: string
  customer_birth_year: string
}

export type Order = FormattedAddress & {
  billing: BillingData | null
  payment: string | undefined
  date: string | undefined
  marketing: boolean | undefined
  terms: boolean | undefined
}

export const validatePhone = (phone: string): boolean => {
  const isBO = secrets.COUNTRY === Country.BOLIVIA

  const withPlus = /^[+][0-9]{9,12}$/
  const withoutPlus = /^[0-9]{9,11}$/g
  let regex = phone.match(/[+]/g) ? withPlus : withoutPlus

  if (isBO) {
    regex = /^[0-9]{8,11}$/g
  }

  return regex.test(phone)
}

export const getBillingSchema = (isBolivia = false) => {
  let DNIRules = yup.string().required('Debes completar este campo')

  if (!isBolivia) {
    DNIRules = DNIRules.test('Rut Test', 'Por favor, ingresa un rut válido', (value) => validateRut(value || ''))
  }

  let shapeObject: yup.ObjectSchemaDefinition<BillingData> = {
    billing_first_name: yup
      .string()
      .required('Debes completar este campo')
      .test('Name Test', 'Debes ingresar un nombre válido', (val) => validateInputField(val ?? '', 'LETTERS_ONLY')),
    billing_last_name: yup
      .string()
      .required('Debes completar este campo')
      .test('LastName Test', 'Debes ingresar un apellido válido', (val) =>
        validateInputField(val ?? '', 'LETTERS_ONLY'),
      ),
    billing_address: yup.string().required('Debes completar este campo'),
    billing_user_id: DNIRules,
  }

  if (isBolivia) {
    shapeObject = {
      ...shapeObject,
      billing_city: yup.string().required('Debes seleccionar una ciudad'),
    }
  }

  return yup.object().shape(shapeObject)
}

const loggedCheckoutSchema = yup.object().shape({
  addressId: yup.string().required('Debes seleccionar una dirección'),
  payment: yup.string().required('Debes seleccionar una forma de pago'),
  date: yup.string().required('Debes seleccionar una fecha de despacho'),
  terms: yup.string().required('Debes aceptar los terminos y condiciones').oneOf(['true']),
  shipping_instructions: yup.string().max(200, (max) => `No debe exceder los ${max.max} caracteres`),
})

const orderSchema = yup.object().shape({
  shipping_address: yup.string().required('Debes seleccionar una dirección'),
  shipping_name: yup.string().required('Debes completar este campo'),
  shipping_phone: yup
    .string()
    .required('Debes completar este campo')
    .test('Phone Test', 'Debes ingresar un teléfono válido', (value) => validatePhone(value || '')),
  shipping_instructions: yup.string().max(200, (max) => `No debe exceder los ${max.max} caracteres`),
  payment: yup.string().required('Debes seleccionar una forma de pago'),
  date: yup.string().required('Debes seleccionar una fecha de despacho'),
  terms: yup.string().required('Debes aceptar los terminos y condiciones').oneOf(['true']),
})

const updateAddresses = (addresses: FormattedAddress[], newAddress: FormattedAddress, id?: string) => {
  addresses.forEach((address, i) => {
    if (address.id === id) addresses.splice(i, 1, newAddress)
    address.isFavorite = false // eslint-disable-line
  })

  if (!id) addresses.push(newAddress)

  return addresses
}

const updateFavorites = (addresses: FormattedAddress[], isFavorite: boolean, id?: string) => {
  const newArray = addresses.map((address) => {
    const newAddress = { ...address }
    if (isFavorite) newAddress.isFavorite = false
    if (address.id === id) newAddress.isFavorite = isFavorite

    return newAddress
  })

  return newArray
}

export { orderSchema, loggedCheckoutSchema, updateAddresses, updateFavorites }
