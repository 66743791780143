import React, { useState } from 'react'
import loadable from '@loadable/component'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import {
  Collapse,
  RadioButtons,
  BillingData,
  PaymentTypes,
  PaymentMethodsObject,
  toCssPrefix,
  PaymentMethodType,
} from '@ecommerce/shared'
import { CreditCardIcon } from '../../../Icons'
import { ErrorMessage } from '../Layout'
import { getStaticPaymentMethods } from './config'
import { TemplateCheckout } from '../../../../types/PgPages'
import TabsOptions from './TabsOptions'
import InfoBadge from '../../../InfoBadge'

const BillingForm = loadable(() => import('../BillingForm'))
const SecurityDisclosure = loadable(() => import('./SecurityDisclosure'))

type Props = {
  isBO: boolean
  paymentOptions: PaymentMethodsObject
  onRetry: () => Promise<void>
  hasError: boolean
  onPaymentTypeChange: (type: PaymentTypes) => void
  onChange: (value: string, name?: string) => void
  onBillingChange: (isShown: boolean) => void
  onBillingSubmit: (data: BillingData) => void
  templateData: TemplateCheckout
  onSelectQR: (hasQR: boolean) => void
}

const { cssPrefix, toPrefix } = toCssPrefix('CheckoutPaymentMethods__')

const Wrapper = styled.div`
  .${cssPrefix} {
    &inner {
      border: 1px solid ${({ theme }) => theme.colors.medium};
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    &options-wrapper {
      .BO_RadioButtons {
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid ${({ theme }) => theme.colors.black10};
        margin-bottom: 20px;
      }
    }

    &options {
      padding: 20px 15px 0;
    }

    &option-text {
      margin: 0 0 25px;
    }
  }
`

const PaymentMethods = ({
  isBO,
  onBillingChange,
  onBillingSubmit,
  onChange,
  paymentOptions,
  onPaymentTypeChange,
  onRetry,
  hasError,
  templateData,
  onSelectQR,
}: Props) => {
  const [paymentType, setPaymentType] = useState<PaymentTypes>(isBO ? PaymentTypes.REDENLACE : PaymentTypes.CL)

  const handlePaymentTypeChange = (selected: PaymentMethodType): void => {
    const type = selected.value

    setPaymentType(type)
    onChange('')
    return onPaymentTypeChange(type)
  }

  const paymentIsOnLine = paymentType === PaymentTypes.REDENLACE || paymentType === PaymentTypes.CL

  const {
    paymentSourceLink,
    privacyPolicyLink,
    onDeliveryPaymentText,
    qrPaymentText,
    paymentSourceLogo: {
      file: { url: paymentSourceLogo },
    },
  } = templateData

  const QRID = paymentOptions.types.filter((type) => type.QRID)[0]?.QRID
  const staticPaymentMethods = React.useMemo(() => getStaticPaymentMethods(QRID), [QRID])

  const onOptionChange = (value: string, label?: string) => {
    const hasQR = staticPaymentMethods.filter((option) => `${option.QRID}` === value)[0]

    if (paymentType === PaymentTypes.CONTRA_ENTREGA || paymentType === PaymentTypes.QR) {
      setPaymentType(hasQR ? PaymentTypes.QR : PaymentTypes.CONTRA_ENTREGA)
    } else {
      setPaymentType(isBO ? PaymentTypes.REDENLACE : PaymentTypes.CL)
    }

    onSelectQR(!!hasQR)
    if (hasQR) onPaymentTypeChange(PaymentTypes.QR)

    return onChange(value, label)
  }

  return (
    <Collapse isOpen className="Collapse" icon={<CreditCardIcon />} label="Forma de pago" customMaxHeight={2000}>
      <Wrapper className={`${cssPrefix} Collapse__content Payments__content`}>
        <div className={toPrefix('inner')}>
          {hasError && (
            <ErrorMessage>
              <p>
                Ha habido un error obteniendo los medios de pago disponibles, por favor
                <button type="button" onClick={onRetry}>
                  intenta de nuevo
                </button>
              </p>
            </ErrorMessage>
          )}

          <div className={toPrefix('options-wrapper')}>
            {paymentOptions?.types.length !== 0 && (
              <TabsOptions
                activeOption={paymentType === PaymentTypes.QR ? PaymentTypes.CONTRA_ENTREGA : paymentType}
                onOptionSelect={handlePaymentTypeChange}
                options={paymentOptions.types}
              />
            )}
            <motion.div
              key={`${paymentIsOnLine}`}
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ type: 'tween', duration: 0.65 }}
              className={toPrefix('options')}
            >
              {!paymentIsOnLine && <p className={toPrefix('option-text')}>{onDeliveryPaymentText}</p>}
              <RadioButtons
                className="RadioButtons PaymentOptions"
                name="payment"
                items={paymentIsOnLine ? paymentOptions.options : staticPaymentMethods}
                onChange={(e, label) => {
                  onOptionChange(e.target.value, label)
                }}
                dataTest="checkout-payment-option"
              />

              <AnimatePresence>
                {paymentType === PaymentTypes.QR && (
                  <motion.div
                    key={paymentType}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.3 }}
                  >
                    <InfoBadge text={qrPaymentText} />
                  </motion.div>
                )}
              </AnimatePresence>

              <SecurityDisclosure
                image={paymentIsOnLine ? paymentSourceLogo : undefined}
                link={paymentIsOnLine ? paymentSourceLink : undefined}
                policyLink={paymentIsOnLine ? privacyPolicyLink : undefined}
              />
            </motion.div>
          </div>
        </div>
        {!isBO && <BillingForm isBO={isBO} onSubmit={onBillingSubmit} onChange={onBillingChange} />}
      </Wrapper>
    </Collapse>
  )
}
export default PaymentMethods
