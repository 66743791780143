import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Country, TextField, toCssPrefix, spinner, ActionApplyCoupon, ActionRemoveCoupon } from '@ecommerce/shared'
import { Icon } from '../../../Icon/Icon'

type Props = {
  appliedCoupon: string | undefined
  onApplyCoupon: ActionApplyCoupon
  onRemoveCoupon?: ActionRemoveCoupon
  onLoading: (isLoading: boolean) => void
  country: Country
  orderId: string
  canUndo?: boolean
}

type ErrorResponse = {
  message: string
  detail?: string
  code?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('CouponInput__')

const Wrapper = styled.div`
  margin-top: 20px;
  position: relative;

  .${cssPrefix} {
    &apply-button {
      color: ${({ theme }) => theme.colors.primary};
      background: none;
      border: none;
      position: absolute;
      right: 20px;
      top: 14px;
      font-size: 15px;
      cursor: pointer;
    }

    &loader {
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      right: 42px;
      top: 16px;
      border-style: solid;
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.primary};
      border-bottom-color: transparent;
      border-left-color: transparent;
      animation: ${spinner} 0.5s infinite linear;
    }

    &text-field {
      input {
        padding-right: 90px;
      }
    }
  }
`

const InfoMessage = styled.div<{ type: 'success' | 'error' }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;

  .success-message {
    color: ${({ theme, type }) => (type === 'success' ? theme.colors.success : theme.colors.error)};
    font-size: 12px;
    margin: 0;
  }

  svg {
    margin-right: 5px;
    fill: ${({ theme, type }) => (type === 'success' ? theme.colors.success : theme.colors.error)};
  }
`

const INVALID_COUPON_ERROR = 'El cupón que ingresaste no es válido.'
const GENERIC_ERROR_MESSAGE = 'Ha habido un error, por favor intenta de nuevo.'

const CouponInput = ({
  appliedCoupon,
  onApplyCoupon: applyCoupon,
  onRemoveCoupon: removeCoupon,
  onLoading,
  orderId,
  country,
  canUndo = true,
}: Props) => {
  const [couponCode, setCouponCode] = useState(appliedCoupon ?? '')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(!!appliedCoupon)
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: '',
  })

  useEffect(() => {
    setCouponCode(appliedCoupon ?? '')
    setIsSuccess(!!appliedCoupon)
  }, [appliedCoupon])

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget

    setCouponCode(value)
  }

  const setError = (e: any) => {
    let errorData = { message: GENERIC_ERROR_MESSAGE } as ErrorResponse

    if (e?.response?.data?.error?.message) {
      const { message = INVALID_COUPON_ERROR, detail, code } = e?.response?.data?.error

      errorData = { ...errorData, message: message ?? GENERIC_ERROR_MESSAGE, detail, code }

      if (!e?.response?.data?.error?.code) {
        errorData.message = GENERIC_ERROR_MESSAGE
      }
    }

    setErrorState({
      hasError: true,
      message: errorData.message,
    })
  }

  const onApplyCoupon = async () => {
    try {
      setErrorState({ hasError: false, message: '' })
      setIsLoading(true)
      onLoading(true)

      await applyCoupon({ code: couponCode, orderId, country })

      setIsSuccess(true)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
      onLoading(false)
    }
  }

  const onRemoveCoupon = async () => {
    try {
      if (!removeCoupon) return
      setErrorState({ hasError: false, message: '' })
      setIsLoading(true)
      onLoading(true)

      await removeCoupon({ code: couponCode, orderId, country })
      setIsSuccess(false)
      setCouponCode('')
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
      onLoading(false)
    }
  }

  return (
    <Wrapper className={cssPrefix}>
      <TextField
        data-testid="coupon-code"
        min={6}
        disabled={isLoading || isSuccess}
        onChange={handleChange}
        value={couponCode}
        className={toPrefix('text-field')}
        placeholder="Ingresar código"
        name="coupon-code"
        status={errorState.hasError ? 'error' : undefined}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      />

      {isLoading ? (
        <span className={toPrefix('loader')} />
      ) : (
        <>
          {(isSuccess && canUndo) || (couponCode.length > 5 && !isSuccess) ? (
            <button
              onClick={() => {
                return isSuccess && canUndo ? onRemoveCoupon() : onApplyCoupon()
              }}
              type="button"
              className={toPrefix('apply-button')}
            >
              {isSuccess && canUndo ? 'Quitar' : 'Aplicar'}
            </button>
          ) : null}
        </>
      )}

      {isSuccess || errorState.hasError ? (
        <InfoMessage type={isSuccess ? 'success' : 'error'}>
          <Icon sizeHeight={15} sizeWidth={15} iconId={isSuccess ? 'success' : 'error_outline'} />
          <p className="success-message">
            {errorState.hasError ? errorState.message : 'El cupón fue aplicado con éxito'}
          </p>
        </InfoMessage>
      ) : null}
    </Wrapper>
  )
}

export default CouponInput
