import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

const Layout = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.medium};
  padding: 120px 0 106px;

  @media screen and (${breakpoints.tabletLandscape.max}) {
    padding: 70px 0 0;
  }
`
const Wrapper = styled.div`
  max-width: 1000px;
  min-height: 100vh;
  margin: auto;
  padding: 34px 92px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
  position: relative;

  h2 {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
  }

  .Checkout__steps {
    margin: 50px auto;
  }

  .CheckoutForm {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    padding: 5px 0 35px 0;

    .Checkout__steps {
      margin: 30px auto;
    }

    .CheckoutForm {
      flex-direction: column;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) and (${breakpoints.tabletLandscape.max}) {
    padding-right: 200px;
    padding-left: 200px;
  }
`

const Section = styled.div`
  width: calc(50% - 30px);

  .Section__content {
    border: 1px solid ${({ theme }) => theme.colors.medium};
    border-radius: ${({ theme }) => theme.borderRadius};

    .PaymentSectionCollapse {
      margin-top: 40px;

      .payment-section {
        padding: 0 20px;
      }
    }
  }

  .link {
    &.is-red {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.is-black {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .PayButton,
  .SaveBillingButton {
    width: 70%;
    margin: auto;
    display: block;

    &.is-invalid,
    &.is-disabled {
      opacity: 0.25;
    }

    &.is-disabled {
      pointer-events: none;
    }
  }

  .PayButton {
    margin-bottom: 20px;
  }

  .Collapse {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    .Collapse__content {
      padding: 20px;

      &.Payments__content {
        span {
          color: ${({ theme }) => theme.colors.black};
        }
      }

      &.address-form {
        input,
        textarea {
          margin-bottom: 10px;
        }

        .user-gretting {
          margin-bottom: 15px;

          p {
            font-weight: bold;
            margin-bottom: 2px;
          }

          span {
            display: block;
          }

          .form-action-title {
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            color: ${({ theme }) => theme.colors.black};

            span {
              font-weight: normal;
            }
          }
        }

        span {
          color: ${({ theme }) => theme.colors.black};
          display: block;

          &.UserLocation {
            font-weight: 600;
            margin-bottom: 20px;
          }
        }

        .instructions {
          p {
            display: block;
            font-weight: normal;
            margin: 20px 0 12px;
            font-size: 12px;
          }

          textarea {
            margin-top: 10px;
            resize: none;
            height: 85px;
          }

          .shipping-notes-error {
            margin-bottom: 15px;

            span {
              color: ${({ theme }) => theme.colors.error};
            }

            svg {
              fill: ${({ theme }) => theme.colors.error};
            }
          }
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    width: 100%;

    &:first-child {
      padding-right: 0;
    }

    .Section__content {
      border: none;

      &.user-info {
        margin-bottom: 14px;
      }
    }
  }
`

const CheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0;
  padding: 0 20px;

  span {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 13px;
  }
`

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};

  button {
    color: inherit;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
  }

  &.payment--error {
    text-align: center;
    font-weight: 500;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    p {
      margin-top: 0;
      text-align: left;

      span {
        display: block;
        width: 100%;
      }
    }
  }
`

const UserAddressesWrapper = styled.div`
  &.UserAddressesWrapper {
    .add-new-address {
      font-size: 15px;
      color: ${({ theme }) => theme.colors.black};
      text-align: center;

      button {
        padding: 0;
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
        cursor: pointer;
      }
    }

    .confirm-address {
      width: 100%;
      margin: 25px auto 0 auto;
      display: block;
      opacity: 1;

      &.is-invalid {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
`

const BackButton = styled.button`
  position: absolute;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.black};
  top: 40px;
  left: 90px;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 6px;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    position: fixed;
    top: 24px;
    left: 25px;
    z-index: 4;
    font-size: 0;

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 45%;

    &.address-back-button {
      background: none;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &.is-invalid,
    &.is-disabled {
      opacity: 0.5;
    }

    &.w-75 {
      width: 75%;
      margin: auto;
    }
  }

  &.full-width-buttons {
    button {
      width: 75%;
      margin: auto;
    }
  }
`

export { Layout, Wrapper, Section, CheckboxOption, ErrorMessage, UserAddressesWrapper, BackButton, ButtonsWrapper }
