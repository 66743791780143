import React, { useState, useEffect } from 'react'
import { Collapse, Select, getAvailableDates, Country, SelectOption, AvailableDate } from '@ecommerce/shared'
import { ErrorMessage } from '../Layout'
import { CalendarIcon } from '../../../Icons'

type Props = {
  cityId: number
  country: Country
  onChange: (date: string) => void
  selectedCommune?: string
}

const customCommunesSettings = [
  { name: 'Dichato', allowedDays: [6] },
  { name: 'Pingueral', allowedDays: [6] },
  { name: 'Mantagua', allowedDays: [2, 5] },
  { name: 'Placilla y Curauma', allowedDays: [1, 3, 4, 6] },
]

const DeliveryDates = ({ cityId, country, onChange, selectedCommune }: Props) => {
  const [hasError, setHasError] = useState(false)
  const [deliveryDates, setDeliveryDates] = useState<SelectOption[]>([])
  const [showEmptyDatesMessage, setShowEmptyDatesMessage] = useState(false)

  const filterDates = (dates: AvailableDate[]): SelectOption[] => {
    const communeSettings = customCommunesSettings.find(({ name }) => name === selectedCommune)

    if (!communeSettings) return dates

    return dates.filter(({ weekDay }) => communeSettings.allowedDays.includes(weekDay))
  }

  // Fetch available dates
  const fetchDates = async () => {
    try {
      // Clear errors
      setHasError(false)
      setShowEmptyDatesMessage(false)

      // Fetch dates
      const availableDates = filterDates(await getAvailableDates(cityId, country))

      if (availableDates.length === 0) setShowEmptyDatesMessage(true)

      setDeliveryDates(availableDates)
    } catch {
      setHasError(true)
    }
  }

  useEffect(() => {
    fetchDates()
  }, [selectedCommune])

  return (
    <Collapse isOpen className="Collapse DateSelect" icon={<CalendarIcon />} label="Fecha de despacho">
      <div className="Collapse__content">
        {hasError && (
          <ErrorMessage>
            <p>
              Ha habido un error obteniendo las fechas disponibles, por favor
              <button type="button" onClick={fetchDates}>
                intenta de nuevo
              </button>
            </p>
          </ErrorMessage>
        )}
        <Select
          key={selectedCommune}
          dataTest="checkout-date-select"
          name="date_select"
          label=""
          options={deliveryDates}
          onSelect={(val) => onChange(`${val}`)}
          className="Select__"
          placeholder="Seleccionar el día de despacho"
          changeText="Cambiar"
          errorMessage={showEmptyDatesMessage ? 'No hay fechas disponibles para tu ciudad' : ''}
        />
      </div>
    </Collapse>
  )
}

export default DeliveryDates
