import { Country } from '../../../../libs/shared/src/types/index'

export const PageStaticNamesCL: Record<string, any> = {
  companyUrl: 'http://www.embonor.cl',
  companyName: 'Embonor',
  coverage: 'cobertura',
  covid: 'covid-19',
  faqs: 'ayuda',
  legal: 'bases-legales',
  privacyPolicy: 'politica-de-privacidad',
  returnability: 'retornabilidad',
  terms: 'terminos-y-condiciones',
  'product-redirect': 'product',
  'search-redirect': 'search',
  'promotion-redirect': 'promotion',
  'category-redirect': 'category',
  'moment-redirect': 'moment',
}

export const PageStaticNamesBO: Record<string, any> = {
  companyUrl: 'https://embol.com/',
  companyName: 'Embol',
  coverage: 'cobertura',
  covid: 'covid-19',
  faqs: 'ayuda',
  legal: 'bases-legales',
  privacyPolicy: 'politica-de-privacidad',
  returnability: 'retornabilidad',
  terms: 'terminos-y-condiciones',
  'product-redirect': 'product',
  'search-redirect': 'search',
  'promotion-redirect': 'promotion',
  'category-redirect': 'category',
  'moment-redirect': 'moment',
}

export const getPageStaticNames = (countryCode: Country) =>
  countryCode === 'BO' ? PageStaticNamesBO : PageStaticNamesCL
