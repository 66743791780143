import React from 'react'
import styled from 'styled-components'
import { PaymentMethodType, toCssPrefix } from '@ecommerce/shared'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  options: PaymentMethodType[]
  onOptionSelect: (selected: PaymentMethodType) => void
  activeOption: string
}

const { cssPrefix, toPrefix } = toCssPrefix('PaymentMethodsTabs__')

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  .${cssPrefix} {
    &active-indicator {
      width: 50%;
      height: 2px;
      display: block;
      background: ${({ theme }) => theme.colors.primary};
      position: absolute;
      bottom: 0;
      transform: translate3d(0%, 0px, 0px);
      transition: 0.2s;

      &.option-selected-is-1 {
        transform: translate3d(100%, 0px, 0px);
      }
    }

    &option {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 50%;
      background: none;
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.colors.medium};
      color: ${({ theme }) => theme.colors.dark};
      position: relative;
      cursor: pointer;
      transition: color 0.2s;

      &.is-active {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
      }

      &:first-child {
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          height: 45px;
          width: 1px;
          top: 10px;
          background: ${({ theme }) => theme.colors.medium};
        }
      }
    }
  }
`

const TabsOptions = ({ options, onOptionSelect, activeOption }: Props) => {
  const activeIndex = options.findIndex((option) => option.value === activeOption)

  return (
    <Wrapper className={cssPrefix}>
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => onOptionSelect(option)}
          type="button"
          className={`${toPrefix('option')} ${activeOption === option.value ? 'is-active' : ''}`}
          title={option.label}
        >
          {option.label}
        </button>
      ))}
      <span className={toPrefix(`active-indicator option-selected-is-${activeIndex}`)} />
    </Wrapper>
  )
}

export default TabsOptions
