import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import { WindowWithMaps } from '@ecommerce/shared'
import secrets from '../config/secrets'
import withPageTransition from '../components/withPageTransition'
import { PgPageProps } from '../types/PgPages'
import useTimeoutModal from '../components/Checkout/hooks/useTimeoutModal'
import { Layout, Wrapper, BackButton } from '../components/Checkout/components/Layout'
import CheckoutWrapper from '../components/Checkout/components/CheckoutWrapper'
import { Icon } from '../components/Icon/Icon'
import PageLayout from '../components/Layout/LayoutStaticPage'

declare const window: WindowWithMaps

const CheckoutPage = ({ data, pageContext: { currentCity }, location: { state: locationState } }: PgPageProps) => {
  const {
    inactivityAlertTime,
    inactivityRedirectTime,
    inactivityModalText,
    inactivityModalButtonText,
  } = data.allContentfulPgPage.edges[0].node.template
  const templateData = data.allContentfulPgPage.edges[0].node.template

  const { renderTimeoutModal } = useTimeoutModal({
    inactivityAlertTime,
    inactivityRedirectTime,
    redirectSlug: `/${currentCity.slug}`,
    alertContent: {
      text: inactivityModalText,
      buttonText: inactivityModalButtonText,
    },
  })

  const beforeUnload = () => {
    // Set maps api to null to avoid unwanted side effects when navigating between pages
    if (window.google?.maps) window.google.maps = null

    // Clear location state referrer to avoid people from accessing the page directly
    window.history.replaceState(null, '')
  }

  useEffect(() => {
    if (!locationState || !locationState?.referrer) {
      navigate(`/${currentCity.slug}`)
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      beforeUnload()
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [])

  return (
    <PageLayout title={data.allContentfulPgPage.edges[0].node.title}>
      <Helmet>
        <script src={secrets.GATSBY_GOOGLE_MAPS_URL} />
      </Helmet>
      {renderTimeoutModal()}
      <Layout>
        <Wrapper>
          <BackButton onClick={() => navigate('../', { state: {} })}>
            <Icon iconId="arrow_left" />
            Volver
          </BackButton>
          <CheckoutWrapper templateData={templateData} currentCity={currentCity} />
        </Wrapper>
      </Layout>
    </PageLayout>
  )
}

export default withPageTransition(CheckoutPage)

export const query = graphql`
  query CheckoutPageQuery($checkoutId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $checkoutId } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTmCheckout {
              id
              onDeliveryPaymentText
              qrPaymentText
              privacyPolicyLink
              paymentSourceLink
              inactivityModalText
              inactivityModalButtonText
              inactivityAlertTime
              inactivityRedirectTime
              outOfStockModalTitle
              outOfStockModalValidTotalText
              outOfStockModalInvalidTotalText
              outOfStockModalContinueText
              outOfStockModalResumeTitle
              outOfStockModalBackToCart
              outOfStockModalBackInvalid
              paymentSourceLogo {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
