import { navigate } from 'gatsby'
import { Product, sendClickProductToGTM } from '@ecommerce/shared'
import secrets from '../config/secrets'

const { COUNTRY } = secrets

type ProductNavigateParams = {
  product: Product
  listName: string
  slugLocation: string
  navigationState?: Record<string, unknown>
}

export const tagManagerCheckoutActionFields = {
  init: { actionField: { step: 1, option: 'Inicio checkout' } },
  shippingAddress: { actionField: { step: 2, option: 'Dirección' } },
  shippingDate: (date: string) => ({ actionField: { step: 3, option: date } }),
  paymentMethod: (paymentMethod: string) => ({ actionField: { step: 4, option: paymentMethod } }),
  confirmCheckout: { actionField: { step: 5, option: 'Confirmado' } },
  cancelled: { actionField: { step: 6, option: 'Cancelado' } },
}

export const productNavigate = ({ product, listName, slugLocation, navigationState = {} }: ProductNavigateParams) => {
  sendClickProductToGTM(product, listName || '', COUNTRY === 'BO')

  return navigate(`/${slugLocation ?? product.slugLocation}/products/${product.skuCode}`, {
    state: { originListName: listName, ...(navigationState || {}) },
  })
}
