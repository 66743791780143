import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import loadable from '@loadable/component'
import {
  useResolution,
  Checkbox,
  CheckoutSteps,
  Collapse,
  Title,
  TextArea,
  TextField,
  OrderResume,
  Button,
  useShoppingCart,
  Geocode,
  checkAddress,
  useAuth,
  fetchAddresses,
  createUserAddress,
  FormattedAddress,
  updateAddress,
  updateFavoriteAddress,
  getPaymentMethods,
  BillingData,
  Order,
  City,
  Coordinates,
  getOrderId,
  getAuth,
  sendCheckoutEventToGTM,
  OrderData,
  useDistricts,
  useLocation,
  PaymentTypes,
  PaymentMethodsObject,
  ProductCart,
  getCartStateByOrderId,
  getCartId,
  setCartId,
  useFirestore,
  calculateCartTotals,
  cleanShoppingCart,
  autoRetry,
  log,
  finishOrderCheckout,
  getGuest,
  toCurrencyNumber,
  showToast,
  AddressCard,
} from '@ecommerce/shared'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { navigate } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import { Section, CheckboxOption, ButtonsWrapper, UserAddressesWrapper } from './Layout'
import { TruckIcon, CalendarIcon, CreditCardIcon } from '../../Icons'
import { loggedCheckoutSchema, updateAddresses, updateFavorites, orderSchema } from '../utils'
import { Icon } from '../../Icon/Icon'
import { PageStaticNamesBO, PageStaticNamesCL } from '../../../i18n'
import secrets from '../../../config/secrets'
import { DeviceFingerprintHelmet } from './DeviceFingerprintHelmet'
import { TemplateCheckout } from '../../../types/PgPages'
import CouponInput from './CouponInput'
import { ErrorLevel, sendMessageToSentry, ErrorSource } from '../../../utils/sentry'
import { tagManagerCheckoutActionFields } from '../../../utils/gtm'
import SkeletonScreen from './SkeletonScreen'
import DeliveryDates from './DeliveryDates'
import PaymentMethods from './PaymentMethods'

const loadableOptions = {
  ssr: false,
}

const NoStockModal = loadable(() => import('./NoStockModal'), loadableOptions)
const PaymentHandlerButton = loadable(() => import('./PaymentHandlerButton'), loadableOptions)
const NoAddress = loadable(() => import('./NoAddress'), loadableOptions)
const OutOfRangeModal = loadable(() => import('./OutOfRangeModal'), loadableOptions)
const AddressInput = loadable(() => import('./AddressInput'), loadableOptions)
const ErrorMessage = loadable(() => import('./Layout'), {
  resolveComponent: (components) => components.ErrorMessage,
  ...loadableOptions,
})

const steps = [
  {
    icon: <TruckIcon />,
    label: 'Envio',
  },
  {
    icon: <CalendarIcon />,
    label: 'Entrega',
  },
  {
    icon: <CreditCardIcon />,
    label: 'Pago',
  },
]

const { COUNTRY } = secrets

interface Props {
  currentCity: City
  templateData: TemplateCheckout
}

const CheckoutWrapper = ({ currentCity, templateData }: Props) => {
  // Logged user settings
  const {
    state: { ownerId, firstName, lastName },
  } = useAuth()
  const isAuth = getAuth()

  // User addresses settings
  const [userAddresses, setUserAddresses] = useState<FormattedAddress[]>([])
  const [edittingAddress, setEdittingAddress] = useState<FormattedAddress | null>(null)
  const [isAddingAddress, setIsAddingAddress] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState<string | undefined>()
  const [noAddressAvailable, setNoAddressAvailable] = useState(false)
  const [shouldClearAddress, setShouldClearAddress] = useState(false)
  const {
    state: cartContextState,
    applyCouponOrGiftCard,
    removeCouponOrGiftCard,
    createShoppingCart,
  } = useShoppingCart()
  const { placeFsShoppingCart } = useFirestore()

  const validationSchema = !isAuth || isAddingAddress || edittingAddress !== null ? orderSchema : loggedCheckoutSchema

  const { register, errors, watch, formState, setValue, trigger, clearErrors } = useForm<OrderData>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })
  const { isDesktop } = useResolution()
  const [showAddress, setShowAddress] = useState(true)
  const [processErrors, setProcessErrors] = useState({
    paymentMethods: false,
    payment: false,
  })

  // Loading states
  const [isLoadingUserData, setIsLoadingUserData] = useState(isAuth)
  const [savingAddress, setSavingAddress] = useState(false)

  const [addressIsValidated, setAddressIsValidated] = useState(false)
  const [showOutOfRangeModal, setShowOutOfRangeModal] = useState(false)

  const [geocode, setGeocode] = useState<Geocode>()
  const [step, setStep] = useState(1)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsObject>({
    options: [],
    types: [],
  })

  const [cartState, setCartState] = useState(cartContextState)

  const refOrderId = useRef(getOrderId() || '')
  const refSessionId = useRef(uuidv4())
  const refGuestUser = useRef(getGuest())

  // Location settings
  const { id: cityId, name: city, internalName, googleEmbedId: mapID } = currentCity
  const { setDistrictState, districtState, resetDistrictState } = useDistricts({ edittingAddress, city })

  // Billing data settings
  const [useDifferentBillingData, setUseDifferentBillingData] = useState(false)
  const [billingData, setBillingData] = useState<BillingData | null>(null)

  const { toCurrency, isBolivia, textByCountry } = useLocation()

  // Payment type
  const isBO = isBolivia()
  const [paymentType, setPaymentType] = useState<PaymentTypes>(isBO ? PaymentTypes.REDENLACE : PaymentTypes.CL)

  useEffect(() => {
    if (isBO) setPaymentType(PaymentTypes.REDENLACE)
  }, [isBO])

  // Fetch available payment methods
  const fetchPayments = async () => {
    try {
      const methods = await getPaymentMethods(COUNTRY)

      setPaymentMethods(methods)
      setProcessErrors((prev) => ({ ...prev, paymentMethods: false }))
    } catch (error) {
      setProcessErrors((prev) => ({ ...prev, paymentMethods: true }))
    }
  }

  // Clear address input value if is out of range
  const clearAddresssInput = () => {
    // Clear values
    setValue('shipping_address', '')

    setShouldClearAddress(true)
    setGeocode(undefined)

    setShowOutOfRangeModal(false)
  }

  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false)
  const [isQRSelected, setIsQRSelected] = useState(false)

  const sendGTMCheckoutData = useCallback(
    ({ state = cartState, actionField }: { state?: typeof cartContextState; actionField?: Record<string, any> }) => {
      sendCheckoutEventToGTM(
        Object.values(state.byHash).map((product) => ({
          ...product,
          price: toCurrencyNumber(product.price, isBO),
        })),
        actionField,
      )
    },
    [cartState],
  )

  // Address settings
  const addressRef = useRef<string>()
  const scrollRef = useRef<HTMLDivElement>(null)
  const addressesRef = useRef<FormattedAddress[]>([])
  useEffect(() => {
    try {
      // Redirect if !orderId
      if (!refOrderId.current) navigate('/404', { state: {} })

      // Get clayer order
      setIsLoadingCoupon(true)
      const getCartState = () =>
        new Promise((resolve) =>
          getCartStateByOrderId(currentCity.commerceLayer?.market?.number, COUNTRY, refOrderId.current).then(
            ({ state, gift_card_code }) => {
              if (gift_card_code) setCartState({ ...state, globalCouponDiscounted: cartState.globalCouponDiscounted })
              else setCartState(state)
              setIsLoadingCoupon(false)
              resolve(true)
              sendGTMCheckoutData({ state, ...tagManagerCheckoutActionFields.init })
            },
          ),
        )
      autoRetry(getCartState()).catch((e) => {
        setIsLoadingCoupon(false)
        sendMessageToSentry({
          message: `Checkout: ${e.message ? e.message : 'could not get order'}`,
          page: currentCity ? `${currentCity.slug}/checkout` : 'checkout',
          source: ErrorSource.CLayer,
          level: ErrorLevel.Critical,
          metadata: {
            error: e?.response ?? e?.message ?? e,
          },
        })
        navigate(currentCity ? `/${currentCity.slug}` : '/', { state: {} })
      })

      // Get user address if is logged
      if (isAuth) {
        const getAddresses = async () => {
          try {
            const [validAddresses, hasAvailable, favoriteID, allAddresses] = await fetchAddresses(cityId, 'checkout')

            // Show no address available prompt if needed
            if (!hasAvailable) setNoAddressAvailable(true)

            // Update state
            if (validAddresses.length !== 0 && hasAvailable) {
              setSelectedAddressId(favoriteID)
              setUserAddresses(validAddresses)
            }

            if (allAddresses) addressesRef.current = allAddresses

            setIsLoadingUserData(false)
          } catch {
            setIsLoadingUserData(false)
          }
        }

        getAddresses()
      }

      // Register fields
      register({ name: 'date' })
      register({ name: 'payment' })
      register({ name: 'terms' })
      register({ name: 'shipping_address' })
      register({ name: 'addressId' })

      // fetch payment methods
      fetchPayments()
    } catch (e) {
      sendMessageToSentry({
        message: 'Checkout: init Error',
        page: currentCity ? `${currentCity.slug}/checkout` : 'checkout',
        level: ErrorLevel.Critical,
        metadata: {
          error: e?.response ?? e?.message ?? e,
        },
      })
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  useEffect(() => setValue('addressId', selectedAddressId), [selectedAddressId])
  useEffect(() => {
    if (edittingAddress?.region) setDistrictState((prev) => ({ ...prev, selectedRegion: edittingAddress.region }))

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    setValue('shipping_address', edittingAddress?.shipping_address)
  }, [edittingAddress?.region])
  useEffect(() => {
    addressRef.current = edittingAddress?.shipping_address

    if (edittingAddress?.geocode) {
      const { latitude, longitude } = edittingAddress.geocode
      if (latitude && longitude) setGeocode({ latitude, longitude, country: COUNTRY })
    }
  }, [edittingAddress])

  useEffect(() => {
    const state = { ...cartState }

    state.globalCouponDiscounted = cartContextState.globalCouponDiscounted ?? state.globalCouponDiscounted
    state.isRawDiscount = cartContextState.isRawDiscount ?? false
    state.adjustment = cartContextState.adjustment ?? state.adjustment
    state.globalTotalPromotion = cartContextState.globalTotalPromotion ?? 0

    if (cartContextState?.totalGifts) {
      state.totalGifts = cartContextState.totalGifts
    }

    setCartState(state)
  }, [cartContextState])

  // Toggle address collapse display
  const toggleAddressCollapse = () => setShowAddress((prev) => !prev)

  // Save selected address
  const onAddressSelect = (val: string, gcode?: Geocode) => {
    // Save address
    setValue('shipping_address', val)

    // Clear errors
    clearErrors('shipping_address')
    setShouldClearAddress(false)

    // Save address geocode
    if (gcode && edittingAddress !== null) {
      setEdittingAddress(() => ({ ...edittingAddress, geocode: gcode }))
    }

    addressRef.current = val

    setGeocode(gcode)
  }

  // Handle address input change
  const onAddressInputChange = (val: string) => {
    const hasChanged = val !== addressRef.current

    setValue('shipping_address', val)

    if (hasChanged) {
      setGeocode(undefined)
    }

    setAddressIsValidated(!hasChanged)
  }

  // Handle map click
  const handleMapClick = (value: string, gcode: Coordinates): void => {
    setGeocode({ latitude: gcode.lat, longitude: gcode.lng, country: COUNTRY })
    setValue('shipping_address', value)
    setAddressIsValidated(false)

    clearErrors('shipping_address')

    addressRef.current = value
  }

  const watchFields = watch()
  const addressFields: Array<keyof Order> = ['shipping_name', 'shipping_address', 'shipping_phone']
  const addressIsValid =
    watchFields.shipping_name !== '' &&
    watchFields.shipping_name &&
    watchFields.shipping_address !== '' &&
    watchFields.shipping_phone !== '' &&
    errors.shipping_phone === undefined &&
    !errors.shipping_instructions &&
    geocode !== undefined

  // Handle input changes - NOT THE ADDRESS FORM
  const handleChange = (name: keyof Order, value: string, changeStep = true) => {
    setValue(name, value, { shouldValidate: true })

    if (changeStep) setStep((prev) => prev + 1)
  }

  // Handle address form submit
  const handleAddressSubmit = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault()

      // Validate fields
      await trigger(addressFields)

      // Check region
      if (districtState.checkRegion && !districtState.selectedRegion) {
        return setDistrictState((prev) => ({ ...prev, hasError: true }))
      }

      // Recheck for errors
      const shouldPass = errors.shipping_phone === undefined && errors.shipping_address === undefined

      if (addressIsValid && shouldPass && !savingAddress) {
        // Show loader
        setSavingAddress(true)

        // Validate address
        let isInRange = false
        const coordinates = edittingAddress !== null ? edittingAddress.geocode : geocode

        if (coordinates?.latitude && coordinates.longitude) {
          const { latitude, longitude } = coordinates
          isInRange = await checkAddress({ lat: latitude, long: longitude, id: cityId, country: COUNTRY })

          setAddressIsValidated(isInRange)
        }

        // Show modal if needed
        if (!isInRange) {
          setShowOutOfRangeModal(true)
          setSavingAddress(false)
        }

        if (isInRange) {
          if (isAuth) {
            // Save address if user is logged
            if (edittingAddress !== null) {
              // Update on CommerceLayer
              const updated = await updateAddress({
                ownerId,
                id: edittingAddress?.id,
                attributes: {
                  city: internalName,
                  first_name: firstName,
                  last_name: lastName,
                  full_name: `${firstName} ${lastName}`,
                  country_code: COUNTRY,
                  state_code: edittingAddress.cityId.toString(),
                  zip_code: districtState.selectedRegion ?? edittingAddress.region,
                  line_1: watchFields.shipping_address ?? edittingAddress?.shipping_address,
                  line_2: watchFields.shipping_number ?? edittingAddress?.shipping_number,
                  phone: watchFields.shipping_phone ?? edittingAddress?.shipping_phone,
                  notes: watchFields.shipping_instructions ?? edittingAddress?.shipping_instructions,
                  lat: geocode?.latitude ?? edittingAddress.geocode.latitude,
                  lng: geocode?.longitude ?? edittingAddress.geocode.longitude,
                  metadata: {
                    favorite: true,
                    alias: watchFields.shipping_name ?? edittingAddress?.shipping_name,
                    cityName: edittingAddress.cityName ?? city,
                    region: districtState.selectedRegion ?? edittingAddress.region ?? 'region',
                    cityId,
                  },
                },
              })

              // Update state
              const newAddresses = updateAddresses(userAddresses, updated, edittingAddress.id)

              // Update favorites
              updateFavoriteAddress(updateAddresses(addressesRef.current, updated, edittingAddress.id))

              setSelectedAddressId(edittingAddress.id)

              setTimeout(() => {
                setEdittingAddress(null)
                setUserAddresses(newAddresses)
              }, 1000)
            } else if ((geocode && isAddingAddress) || userAddresses.length === 0) {
              const newAddress = await autoRetry<FormattedAddress>(
                createUserAddress({
                  ownerId,
                  id: 'ID',
                  attributes: {
                    city: internalName,
                    first_name: firstName,
                    last_name: lastName,
                    full_name: `${firstName} ${lastName}`,
                    country_code: COUNTRY,
                    state_code: cityId.toString(),
                    zip_code: districtState.selectedRegion ?? city,
                    line_1: watchFields.shipping_address,
                    line_2: watchFields.shipping_number,
                    phone: watchFields.shipping_phone,
                    notes: watchFields.shipping_instructions,
                    lat: geocode?.latitude,
                    lng: geocode?.longitude,
                    metadata: {
                      alias: watchFields.shipping_name,
                      cityId,
                      cityName: city,
                      region: districtState.selectedRegion ?? city,
                      favorite: true,
                    },
                  },
                }),
              )

              const updated = updateAddresses(userAddresses, newAddress)

              // Update favorites
              updateFavoriteAddress(updateAddresses(addressesRef.current, newAddress))

              setUserAddresses(updated)
              setSelectedAddressId(newAddress.id)
              setIsAddingAddress(false)
            }
          }

          if (step <= 2) window.scrollTo(0, 0)
          if (step === 1) setStep(2)

          toggleAddressCollapse()

          // Hide loader
          setSavingAddress(false)

          sendGTMCheckoutData(tagManagerCheckoutActionFields.shippingAddress)
        }
      }
    } catch (error) {
      log.error('ERROR SAVING ADDRESS: ', error)

      sendMessageToSentry({
        message: `On save address: ${error?.message ?? ''}`,
        level: ErrorLevel.Warning,
        page: 'checkout',
        tags: { source: 'cities-api' },
        metadata: { error: { ...error }, fields: watchFields, geocode },
      })

      setSavingAddress(false)
    }
  }

  const selectedUserAddress = userAddresses.find((el) => el.id === selectedAddressId)

  useEffect(() => {
    if (districtState?.selectedRegion !== selectedUserAddress?.region) {
      setDistrictState((prev) => ({ ...prev, selectedRegion: selectedUserAddress?.region }))
      handleChange('date', '', false)
    }
  }, [selectedUserAddress])

  const orderData = {
    ...watchFields,
    payment_type: paymentType,
    shipping_phone: watchFields?.shipping_phone ?? selectedUserAddress?.shipping_phone,
    isQRSelected,
  }

  if (useDifferentBillingData) {
    orderData.billing = billingData
  }

  const handleBillingChange = (isShown: boolean) => {
    setUseDifferentBillingData(!isShown)
  }

  const handleAddressCheck = (isChecked: boolean, key: number) => {
    const id = isChecked ? userAddresses[key].id : undefined

    // Update in state
    const newAddresseses = updateFavorites(userAddresses, isChecked, userAddresses[key].id)
    setSelectedAddressId(id)
    setUserAddresses(newAddresseses)

    // Update in CommerceLayer
    updateFavoriteAddress(updateFavorites(addressesRef.current, isChecked, userAddresses[key].id))
  }

  const handleAddressConfirm = () => {
    if (selectedAddressId) {
      if (step <= 2) window.scrollTo(0, 0)
      if (step === 1) setStep(2)

      toggleAddressCollapse()
    }
  }

  const handleAddressBack = () => {
    if (isAddingAddress) {
      setIsAddingAddress(false)
    } else {
      setEdittingAddress(null)
      setShouldClearAddress(false)
    }

    resetDistrictState()
  }

  // Stock validation
  const [outOfStockProducts, setOutOfStokProducts] = useState<ProductCart[]>([])
  const hideStockModal = () => setOutOfStokProducts([])
  const onStockCheck = (isValidStock: boolean, products?: ProductCart[]) => {
    if (!isValidStock && products && products.length !== 0) {
      setOutOfStokProducts(products)
    }
  }

  const canPayLogged =
    formState.isValid && watchFields.addressId !== undefined && edittingAddress === null && !isAddingAddress
  const canPay = !isAuth ? addressIsValidated && formState.isValid : canPayLogged
  const showAddressForm = !isAuth || userAddresses.length === 0 || edittingAddress !== null || isAddingAddress
  const showLocation = edittingAddress !== null || isAddingAddress

  const beforeRedirectCallback = async () => {
    const stockLocation = currentCity.commerceLayer.stockLocation?.number ?? 0
    await finishOrderCheckout({
      orderId: refOrderId.current,
      stockLocation,
      skus: Object.keys(cartState.byHash).join(),
      country: COUNTRY,
    })

    const currentCartId = getCartId()
    const newCartId = await createShoppingCart()
    setCartId(newCartId)
    if (currentCartId) placeFsShoppingCart(currentCartId)
    cleanShoppingCart()
    sendGTMCheckoutData(tagManagerCheckoutActionFields.confirmCheckout)
  }

  const afterRemoveProduct = (product: ProductCart) => {
    const { [product.skuCode]: removedProduct, ...newByHash } = cartState.byHash
    if (removedProduct) {
      const newTotals = calculateCartTotals(newByHash)
      setCartState({ ...cartState, ...newTotals, byHash: newByHash })
    }
  }

  const totalGifts = cartState?.totalGifts || 0
  const calculateDiscounts = () => {
    if (cartState.isRawDiscount && cartState?.adjustment) {
      return {
        amount: cartState.adjustment - (cartState.globalCouponDiscounted - totalGifts),
        shouldIncreaseTotal: true,
      }
    }

    if (cartState.globalCouponDiscounted > 0) return { amount: cartState.globalCouponDiscounted - totalGifts }

    return { amount: cartState.globalTotalPromotion ?? 0 }
  }

  const { displayTotal, displayDiscounts, skuDiscounts } = useMemo(() => {
    log.trace(cartState)

    const { amount: discounts, shouldIncreaseTotal } = calculateDiscounts()
    const promotionDiscounts = cartState.globalTotalPromotion ?? 0

    let discountsToShow = discounts > 0 ? discounts : 0
    let lineItemsDiscounts = cartState.globalTotalDiscounted
    let totalToShow = cartState.globalTotal + cartState.shippingCost

    if (shouldIncreaseTotal) {
      totalToShow += discounts
      lineItemsDiscounts -= discounts
      lineItemsDiscounts -= promotionDiscounts
      discountsToShow = promotionDiscounts
    } else {
      totalToShow -= discounts
    }

    return {
      displayDiscounts: discountsToShow,
      skuDiscounts: lineItemsDiscounts,
      displayTotal: totalToShow,
    }
  }, [cartState, totalGifts])

  const onPaymentButtonClick = () => {
    if (displayTotal >= 0) return true
    showToast({
      title: 'Faltan productos para completar el mínimo.',
      content: 'Monto mínimo de compra: $10.000',
      type: 'error',
    })
    return false
  }

  const hasBillingData = useDifferentBillingData ? billingData !== null : true

  return (
    <>
      {showOutOfRangeModal && <OutOfRangeModal onClose={clearAddresssInput} mapID={mapID} />}
      {outOfStockProducts.length !== 0 && (
        <NoStockModal
          templateData={templateData}
          hideModalCallback={hideStockModal}
          products={outOfStockProducts}
          afterRemoveProduct={afterRemoveProduct}
        />
      )}
      <DeviceFingerprintHelmet isBO={isBO} city={currentCity} sessionId={refSessionId.current} />
      {isDesktop ? <Title color="#000" text="Despacho y pago" /> : null}
      <CheckoutSteps className="Checkout__steps" currentStep={step} steps={steps} />
      <div className="Checkout__sections-wrapper">
        <form className="CheckoutForm">
          <Section>
            <div ref={scrollRef} className="Section__content user-info">
              {isLoadingUserData ? (
                <SkeletonScreen />
              ) : (
                <Collapse
                  key="address_content"
                  isOpen={showAddress}
                  className="Collapse"
                  icon={<TruckIcon />}
                  label="Datos de despacho"
                  onClick={toggleAddressCollapse}
                  customMaxHeight={2000}
                >
                  <div className="Collapse__content address-form">
                    {isAuth && noAddressAvailable ? (
                      <NoAddress
                        onClick={() => {
                          setNoAddressAvailable(false)
                          setIsAddingAddress(true)
                        }}
                      />
                    ) : (
                      <>
                        {isAuth ? (
                          <div className="user-gretting">
                            {isAuth && !showLocation ? (
                              <p>
                                Hola&nbsp;
                                {firstName}
                                &nbsp;
                                {lastName}
                              </p>
                            ) : null}
                            {edittingAddress !== null || isAddingAddress ? (
                              <span className="form-action-title">
                                {isAddingAddress ? 'Agrega una nueva dirección' : 'Edita tus datos de despacho.'}
                                <span>
                                  {isAddingAddress
                                    ? 'Ingresa los datos de la nueva dirección.'
                                    : 'Ingresa los nuevos datos.'}
                                </span>
                              </span>
                            ) : (
                              <span>
                                {userAddresses?.length === 0 || isAddingAddress ? 'Ingresa ' : 'Selecciona '}
                                la dirección de despacho.
                              </span>
                            )}
                          </div>
                        ) : null}
                        {showLocation || !isAuth ? (
                          <span className="UserLocation">
                            Estás en&nbsp;
                            {city}
                          </span>
                        ) : null}
                        {showAddressForm ? (
                          <>
                            <AddressInput
                              country={COUNTRY}
                              city={city}
                              onSelect={(val, gcode) => onAddressSelect(val, gcode)}
                              onMapClick={handleMapClick}
                              onInputChange={onAddressInputChange}
                              status={!errors?.shipping_address ? undefined : 'error'}
                              errorMessage={errors?.shipping_address?.message}
                              defaultValue={edittingAddress?.shipping_address}
                              defaultGeocode={edittingAddress?.geocode}
                              districtState={{ state: districtState, dispatcher: setDistrictState }}
                              shouldClear={shouldClearAddress}
                            />
                            <TextField
                              data-testid="shipping-number"
                              maxLength={isBO ? 50 : undefined}
                              ref={register}
                              type="text"
                              name="shipping_number"
                              defaultValue={edittingAddress?.shipping_number}
                              label="Depto. / Casa Condominio (Opcional)"
                              placeholder="Depto. 27"
                            />
                            <TextField
                              data-testid="shipping-name"
                              maxLength={isBO ? 50 : undefined}
                              type="text"
                              ref={register}
                              name="shipping_name"
                              defaultValue={edittingAddress?.shipping_name}
                              label="Nombre etiqueta"
                              placeholder="Ej: Casa"
                              status={!errors?.shipping_name ? undefined : 'error'}
                              errorMessage={errors?.shipping_name?.message}
                            />
                            <TextField
                              data-testid="shipping-phone"
                              maxLength={isBO ? 20 : undefined}
                              type="tel"
                              ref={register}
                              name="shipping_phone"
                              defaultValue={edittingAddress?.shipping_phone}
                              label="Teléfono de contacto"
                              placeholder={textByCountry('+56923456789', '23456789')}
                              status={!errors?.shipping_phone ? undefined : 'error'}
                              errorMessage={errors?.shipping_phone?.message}
                            />
                            <TextArea
                              data-testid="shipping-instructions"
                              label="Agrega instrucciones especiales para tu entrega: (Opcional)"
                              name="shipping_instructions"
                              defaultValue={edittingAddress?.shipping_instructions}
                              ref={register}
                              placeholder="(Ej: Dejar en conserjería, tocar timbre para abrir portón, etc.)"
                              maxLength={200}
                              status={!errors.shipping_instructions ? undefined : 'error'}
                              errorMessage={errors.shipping_instructions?.message}
                            />
                            <ButtonsWrapper className={`${userAddresses.length === 0 ? 'full-width-buttons' : ''}`}>
                              {isAuth && userAddresses.length !== 0 ? (
                                <Button
                                  className={`address-back-button ${savingAddress ? 'is-disabled' : 'is-enabled'}`}
                                  onClick={handleAddressBack}
                                >
                                  Volver
                                </Button>
                              ) : null}
                              <Button
                                data-testid="shipping-save-button"
                                isLoading={savingAddress}
                                isDisabled={
                                  isAuth ? (savingAddress && geocode !== undefined) || !addressIsValid : false
                                }
                                onClick={handleAddressSubmit}
                                className={`address-submit ${!isAuth ? 'w-75' : 'w-45'} ${
                                  !isAuth && !addressIsValid ? 'is-invalid' : 'is-valid'
                                }`}
                              >
                                {isAuth ? 'Guardar' : 'Continuar'}
                              </Button>
                            </ButtonsWrapper>
                          </>
                        ) : (
                          <UserAddressesWrapper className="UserAddressesWrapper">
                            {userAddresses?.map((address, key) => (
                              <AddressCard
                                isChecked={userAddresses?.length !== 0 ? address.isFavorite : true}
                                buttonTitle="Editar"
                                title={address.shipping_name}
                                onEdit={() => setEdittingAddress(userAddresses[key])}
                                onCheck={(isChecked) => handleAddressCheck(isChecked, key)}
                                address={{
                                  name: address.name,
                                  city: address.cityName,
                                  address: address.shipping_address,
                                }}
                                key={address?.id}
                              />
                            ))}
                            <p className="add-new-address">
                              ¿Nueva dirección de despacho?
                              <button
                                data-testid="add-new-address"
                                type="button"
                                onClick={() => setIsAddingAddress(true)}
                              >
                                &nbsp;Agregar
                              </button>
                            </p>
                            <Button
                              data-testid="confirm-address"
                              onClick={handleAddressConfirm}
                              className={`confirm-address ${
                                selectedAddressId !== undefined ? 'is-valid' : 'is-invalid'
                              }`}
                            >
                              Confirmar
                            </Button>
                          </UserAddressesWrapper>
                        )}
                      </>
                    )}
                  </div>
                </Collapse>
              )}
              {step >= 2 && (
                <DeliveryDates
                  onChange={(date) => {
                    handleChange('date', date)
                    sendGTMCheckoutData(tagManagerCheckoutActionFields.shippingDate(date))
                  }}
                  cityId={cityId}
                  country={COUNTRY}
                  selectedCommune={districtState.selectedRegion}
                />
              )}
              {step >= 3 && (
                <PaymentMethods
                  isBO={isBO}
                  paymentOptions={paymentMethods}
                  onRetry={fetchPayments}
                  hasError={processErrors.paymentMethods}
                  onChange={(methodID, label) => {
                    handleChange('payment', methodID)
                    sendGTMCheckoutData(tagManagerCheckoutActionFields.paymentMethod(label ?? 'Tarjeta de crédito'))
                  }}
                  onBillingChange={handleBillingChange}
                  onBillingSubmit={setBillingData}
                  onPaymentTypeChange={setPaymentType}
                  templateData={templateData}
                  onSelectQR={setIsQRSelected}
                />
              )}
            </div>
          </Section>
          {step >= 3 || isDesktop ? (
            <Section>
              <div className="Section__content">
                <OrderResume
                  className="OrderResume"
                  productsQty={`${cartState.globalQuantity}`}
                  subTotal={toCurrency(cartState.globalRawTotal)}
                  shipping={toCurrency(cartState.shippingCost)}
                  discount={toCurrency(skuDiscounts)}
                  couponDiscount={toCurrency(displayDiscounts)}
                  orderTotal={toCurrency(displayTotal > 0 ? displayTotal : 0)}
                  isRawDiscount={cartState.isRawDiscount ?? false}
                  isAditionalDiscounts={(cartState.hasRawXunitDiscounts ?? false) && !cartState.isRawDiscount}
                />

                <Collapse
                  icon={<Icon iconId="Promociones" />}
                  className="PaymentSectionCollapse"
                  label="Cupón de descuento"
                  isOpen
                >
                  <div className="payment-section">
                    <CouponInput
                      appliedCoupon={cartState.couponCode}
                      country={COUNTRY}
                      onLoading={setIsLoadingCoupon}
                      orderId={refOrderId.current ?? ''}
                      onApplyCoupon={applyCouponOrGiftCard}
                      onRemoveCoupon={removeCouponOrGiftCard}
                    />
                    <CheckboxOption>
                      <Checkbox
                        data-testid="checkout-promotions-checkbox"
                        defaultChecked
                        value="true"
                        name="marketing"
                        ref={register}
                      />
                      <span>Quiero recibir ofertas y promociones</span>
                    </CheckboxOption>
                    <CheckboxOption className="TermCheckbox">
                      <Checkbox
                        value="true"
                        name="terms"
                        onChange={({ target }) => {
                          handleChange('terms', `${target.checked}`, false)
                        }}
                        data-test="checkout-terms-checkbox"
                      />
                      <span>
                        He leído y acepto los&nbsp;
                        <a
                          className="link is-black"
                          href={`/${textByCountry(PageStaticNamesCL.terms, PageStaticNamesBO.terms)}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Términos y Condiciones
                        </a>
                        &nbsp; y soy mayor de 18 años.
                      </span>
                    </CheckboxOption>
                    <PaymentHandlerButton
                      country={COUNTRY}
                      checkStockCallback={onStockCheck}
                      products={cartState.byHash}
                      disabled={!canPay || isLoadingCoupon || !hasBillingData}
                      setHasError={(hasError) => setProcessErrors((prev) => ({ ...prev, payment: hasError }))}
                      orderData={orderData}
                      currentCity={currentCity}
                      geocode={geocode}
                      orderId={refOrderId.current}
                      differentBillingAddress={useDifferentBillingData}
                      buttonText={
                        paymentType === PaymentTypes.CL || paymentType === PaymentTypes.REDENLACE
                          ? 'Ir a pagar'
                          : 'Realizar pedido'
                      }
                      beforeRedirectCallback={beforeRedirectCallback}
                      sessionId={refSessionId.current}
                      onClick={onPaymentButtonClick}
                      guestUser={refGuestUser.current}
                    />
                    {processErrors.payment ? (
                      <ErrorMessage className="payment--error">
                        <Icon iconId="info_fill" />
                        <p>
                          Error en la conexión.
                          <span>Intenta de nuevo por favor.</span>
                        </p>
                      </ErrorMessage>
                    ) : null}
                  </div>
                </Collapse>
              </div>
            </Section>
          ) : null}
        </form>
      </div>
    </>
  )
}

export default CheckoutWrapper
