import React from 'react'
import { skeletonAnimation } from '@ecommerce/shared'
import styled from 'styled-components'

const StyledSection = styled.div`
  width: 100%;
  height: 485px;
  border-radius: ${({ theme }) => theme.borderRadius};
`

const SkeletonItem = styled.div<{ width?: number; height?: number; margin?: string }>`
  background: ${({ theme }) => theme.colors.medium};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '40px')};
  margin: ${({ margin }) => margin ?? '0'};
  opacity: 1;
  animation: ${skeletonAnimation} 1.5s linear infinite;
`

const SkeletonScreen = () => (
  <StyledSection>
    <SkeletonItem />
    <SkeletonItem width={295} height={20} margin="45px 0 4px 16px" />
    <SkeletonItem width={295} height={20} margin="0 0 0 16px" />
    <SkeletonItem width={335} height={170} margin="15px auto" />
    <SkeletonItem width={250} margin="88px auto 0" />
  </StyledSection>
)

export default SkeletonScreen
