import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { sendDataToGTM, minutesToMilliseconds, Button } from '@ecommerce/shared'
import secrets from '../../../config/secrets'

const Alert = loadable(() => import('../../Alert'))

type AlertContent = {
  text: string[]
  buttonText: string
}

type Params = {
  inactivityRedirectTime: number
  inactivityAlertTime: number
  alertContent: AlertContent
  redirectSlug: string
}

type UseInactivityTimeout = (params: Params) => { renderTimeoutModal: () => JSX.Element | null }

type AlertBodyProps = {
  content: AlertContent
  onClose: () => void
}

const AlertWrapper = styled.div`
  font-weight: bold;

  .AlertWrapper__background {
    align-items: center;
  }

  .AlertWrapper__body {
    margin-top: 0;
    width: 350px;
  }

  p.alert-text {
    margin: 8px 0;

    &:nth-child(3) {
      margin: 28px 0 38px;
    }
  }

  .alert-button {
    width: 90%;
    display: block;
    margin: auto;
  }
`

const AlertBody = ({ content: { text, buttonText }, onClose }: AlertBodyProps) => (
  <>
    {text.map((t) => (
      <p key={t} className="alert-text">
        {t}
      </p>
    ))}
    <Button onClick={onClose} className="alert-button">
      {buttonText}
    </Button>
  </>
)

const { CHECKOUT_INACTIVITY_ALERT, CHECKOUT_INACTIVITY_REDIRECT } = secrets

const useTimeoutModal: UseInactivityTimeout = ({
  inactivityAlertTime,
  inactivityRedirectTime,
  alertContent,
  redirectSlug,
}) => {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false)
  const [alertTimeout, setAlertTimeout] = useState(0)
  const [redirectTimeout, setRedirectTimeout] = useState(0)

  const contentfulTimeIsValid = inactivityRedirectTime > inactivityAlertTime
  const alertTime = minutesToMilliseconds(contentfulTimeIsValid ? inactivityAlertTime : CHECKOUT_INACTIVITY_ALERT)
  const redirectTime = minutesToMilliseconds(
    contentfulTimeIsValid ? inactivityRedirectTime : CHECKOUT_INACTIVITY_REDIRECT,
  )

  const clearTimeouts = () => {
    clearTimeout(alertTimeout)
    clearTimeout(redirectTimeout)
  }

  const initTimeout = () => {
    setAlertTimeout(
      setTimeout(() => {
        setShowTimeoutModal(true)
        sendDataToGTM({ pageTitle: 'Checkout', event: 'showModalTimeOutCheckout' })
      }, alertTime),
    )

    setRedirectTimeout(
      setTimeout(() => {
        clearTimeouts()

        navigate(redirectSlug)
      }, redirectTime),
    )
  }

  const onCloseTimeoutModal = () => {
    setShowTimeoutModal(false)
    clearTimeouts()

    initTimeout()
  }

  const renderTimeoutModal = () =>
    showTimeoutModal ? (
      <AlertWrapper>
        <Alert
          backgroundClassName="AlertWrapper__background"
          className="AlertWrapper__body"
          onClose={onCloseTimeoutModal}
          body={<AlertBody content={alertContent} onClose={onCloseTimeoutModal} />}
        />
      </AlertWrapper>
    ) : null

  useEffect(initTimeout, [])

  return {
    renderTimeoutModal,
  }
}

export default useTimeoutModal
